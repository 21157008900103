import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Project from '../components/Project'

const ProjectsPage = ({ data }) => {
  const pageTitle = 'LAURIE ON TECH Projects'

  return (
    <Layout>
      <Helmet title={pageTitle} />
      <section id="banner">
        <div id="banner" className="inner">
          <header>
            <h2 className="hs h2">PROJECTS and OSS Maintenance</h2>
          </header>
        </div>
      </section>
      <section id="speaking" className="wrapper style4 container">
        <div className="container">
          <div className="wrapper" id="speakwrap">
            {data.allProjectsYaml.nodes.map(node => (
              <Project project={node} />
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allProjectsYaml(sort: { fields: [index], order: DESC }) {
      nodes {
        name
        url
        image {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1.66
              transformOptions: { cropFocus: CENTER }
            )
          }
        }
        technologies
      }
    }
  }
`

export default ProjectsPage
