import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Project = ({ project }) => {
  return (
    <div key={project.name} className="conference">
      <h3 className="date hs h3">{project.name}</h3>
      <GatsbyImage
        image={project.image.childImageSharp.gatsbyImageData}
        role="presentation"
      />
      <br />
      <a
        href={project.url}
        className="icon circle fa-github a"
        aria-label="Github"
      >
        <span className="label">Github</span>{' '}
      </a>
      <ul className="no-bullets ul">
        {project.technologies.map(name => (
          <li key={name} className="talk">
            {name}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Project
